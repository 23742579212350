import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../../images/logo.png";
import Barcode from "react-barcode";

export type order = {
  alto: string;
  ancho: string;
  bultos: string;
  ciudad: string;
  contacto: string;
  destinatario: string;
  direccion: string;
  email: string;
  guia: string;
  kilos: string;
  largo: string;
  observaciones: string;
  fecha: string;
};

export const ComponentToPrint = React.forwardRef((props, ref: any) => {
  const node = useRef<HTMLDivElement>();
  const ordersAux: order[] = Object.values(props);
  const [orders, setOrders] = useState<any[]>([]);
  const ordersP: order[] = [];
  //console.log("ordersAux:", ordersAux);

  //const item: any = ordersAux[0];
  //const array: any[] = item["fecha"].split(",");
  //const array2: any[] = array[1].split(" ");
  //const fn = array[2].replace(":", "").replace(":", "");
  //console.log(array2[1] + "" + array2[5]);
  const ordersNew: order[] = [];
  ordersAux.forEach((e) => {
    //console.log(typeof e.bultos)
    if (parseInt(e.bultos) === 1) {
      ordersNew.push(e);
    } else {
      for (let i = 0; i < parseInt(e.bultos); i++) {
        ordersNew.push(e);
      }
    }
  });
  //console.log(ordersNew);
  //console.log(ordersAux)
  return (
    <div ref={ref}>
      <Container>
        {props !== null &&
          ordersNew.map((variant: any, index) => {
            //console.log(variant);
            //console.log(typeof variant.uuid);
            return (
              <Row style={{}} key={index}>
                <Col style={{ height: 370 }} sm={12}>
                  <div
                    style={{
                      height: 270,
                      border: "3px solid",
                      borderRadius: "1rem 1rem 1rem 1em ",
                      borderColor: "#202156",
                      maxWidth: "93%",
                      marginLeft: "0.5rem",
                      marginTop: "0.4rem",
                    }}
                  >
                    <div style={{ height: 400 }}>
                      <img
                        style={{
                          textAlign: "right",
                          justifyContent: "center",
                          marginLeft: "60%",
                          height: 150,
                          marginTop: "0.2rem",
                        }}
                        src={logo}
                      />
                      <p
                        style={{
                          color: "#202156",
                          textAlign: "left",
                          marginTop: "-8.5rem",
                          paddingLeft: "0.5rem",
                          maxWidth: "70%",
                          fontSize: 15,
                          fontWeight: 600,
                        }}
                      >
                        {
                          //fn + array2[1] + "" + array2[5]
                          variant.guia === "." ? variant.uuid : variant.guia
                        }
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          textAlign: "left",
                          paddingLeft: "0.5rem",
                          marginTop: "-0.3rem",
                          maxWidth: "70%",
                          fontSize: 15,
                          fontWeight: 600,
                        }}
                      >
                        DESTINATARIO : {variant.destinatario}
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          textAlign: "left",
                          paddingLeft: "0.5rem",
                          maxWidth: "100%",
                          marginTop: "-0.3rem",
                          fontSize: 15,
                          fontWeight: "bold",
                        }}
                      >
                        CLIENTE: {variant.client}
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          textAlign: "left",
                          paddingLeft: "0.5rem",
                          fontSize: 15,
                          marginTop: "-4.7rem",
                          marginLeft: "3%",
                          fontWeight: 600,
                        }}
                      >
                        {variant.fecha}
                      </p>

                      <p
                        style={{
                          color: "#202156",
                          paddingLeft: "0.5rem",
                          textAlign: "left",
                          marginTop: "3rem",
                          fontSize: 15,
                          fontWeight: 600,
                        }}
                      >
                        DIRECCION : {variant.direccion}
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          textAlign: "left",
                          paddingLeft: "0.5rem",
                          maxWidth: "70%",
                          marginTop: "-0.3rem",
                          fontSize: 15,
                          fontWeight: 600,
                        }}
                      >
                        BULTOS : {variant.bultos} PESO : {variant.kilos} kg
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          paddingLeft: "0.5rem",
                          textAlign: "left",
                          maxWidth: "70%",
                          marginTop: "-0.3rem",
                          fontSize: 15,
                          fontWeight: 600,
                        }}
                      >
                        TELEFONO : {variant.contacto}{" "}
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          paddingLeft: "0.5rem",
                          textAlign: "left",
                          maxWidth: "70%",
                          fontSize: 15,
                          marginTop: "-0.3rem",
                          fontWeight: 600,
                        }}
                      >
                        ORIGEN : {variant.ciudad}{" "}
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          paddingLeft: "0.5rem",
                          textAlign: "left",
                          maxWidth: "80%",
                          fontSize: 30,
                          marginTop: "-0.3rem",
                          fontWeight: 600,
                        }}
                      >
                        DESTINO : {variant.Destino}{" "}
                      </p>

                      <p
                        style={{
                          color: "#202156",
                          paddingLeft: "1rem",
                          textAlign: "left",
                          marginTop: "-2.5rem",
                          fontSize: 15,
                          marginLeft: "62%",
                          fontWeight: 600,
                        }}
                      >
                        LARGO: {variant.largo} cm
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          paddingLeft: "1rem",
                          textAlign: "left",
                          marginTop: "-0.5rem",
                          fontSize: 15,
                          marginLeft: "62%",
                          fontWeight: 600,
                        }}
                      >
                        ALTO: {variant.alto} cm
                      </p>
                      <p
                        style={{
                          color: "#202156",
                          paddingLeft: "1rem",
                          textAlign: "left",
                          marginTop: "-0.5rem",
                          fontSize: 15,
                          marginLeft: "62%",
                          fontWeight: 600,
                        }}
                      >
                        ANCHO: {variant.ancho} cm
                      </p>
                    </div>

                    <div
                      style={{
                        justifyContent: "center",
                        marginTop: "-13rem",
                        marginLeft: "3rem",
                        textAlign: "center",
                      }}
                    >
                      <Barcode
                        value={`${variant.uuid}`}
                        height={20}
                        width={2}
                        fontSize={25}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
      </Container>
    </div>
  );
});
